<template>
	<div class="Help bgfff br4 p32">
		<div v-if="curType == 'list'" class="list">
			<div class="nav fs16 fw-7 font-33 pb24 pr00 flex">
				<div class="tit-line flex">
					<span class="pr">帮助中心</span>
					<span class="line">帮助中心</span>
				</div>
			</div>
			<div style="min-height: 600px" v-loading="listLoading">
				<div v-if="arrs.length > 0 || listLoading">
					<div
						v-for="(item, index) in arrs"
						:key="index"
						class="list-item flex ptb32"
					>
						<div class="name fs16 fw-7 font-33">
							{{ item.name }}
						</div>
						<div @click="getDetail(item)" class="cursor">查看详情</div>
					</div>
				</div>
				<div
					v-else
					class="mt24 bgfff br4"
					style="overflow: hidden; padding-bottom: 60px"
				>
					<Empty></Empty>
				</div>
			</div>
		</div>

		<div v-if="curType == 'detail'" class="detail">
			<!-- 面包屑 start -->
			<div class="bread flex align-center font-33 pb24">
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item
						@click.stop.prevent.native="
							(curType = 'list'),
								(title = ''),
								(body = ''),
								((curObj = {}), (detailLoading = false))
						"
						class="is-link"
						>帮助中心</el-breadcrumb-item
					>
					<el-breadcrumb-item>详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->

			<div v-loading="detailLoading" class="content pb32">
				<div class="fs16 fw-7 font-33">{{ title }}</div>
				<div v-html="body"></div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Help",
	data() {
		return {
			curType: "list", // 当前在那里  list 列表; detail 详情;
			arrs: [],
			listLoading: false,

			detailLoading: false,
			curObj: {}, // 当前是哪个
			title: "",
			body: "",

			scorllTop: 0, // 距离顶部得距离
		};
	},
	watch: {
		curType: {
			handler(newVal) {
				if (newVal == "list") {
					document.documentElement.scrollTop = this.scorllTop;
					document.body.scrollTop = this.scorllTop;
				}
			},
		},
	},
	mounted() {
		window.addEventListener("scroll", this.helpScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.helpScroll);
	},
	created() {
		this.getList();
	},
	methods: {
		helpScroll() {
			if (this.curType == "list") {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				this.scorllTop = scrollTop;
			}
		},
		getDetail(item) {
			if (this.detailLoading) {
				return false;
			}

			this.curType = "detail";

			this.detailLoading = true;
			this.$pageScrollTop();

			this.$API
				.getAgreement({ category_id: item.id })
				.then((res) => {
					this.detailLoading = false;

					if (res.code == 1) {
						this.title = res.data.name;
						this.body = res.data.content;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 数据列表
		getList() {
			if (this.listLoading) {
				return false;
			}

			let { page, pageSize } = this;

			this.listLoading = true;
			this.$pageScrollTop();

			this.$API
				.helpAjax({
					page,
					pagesize: pageSize,
					list_rows: pageSize,
					pageSize,
				})
				.then((res) => {
					this.listLoading = false;

					if (res.code == 1) {
						this.arrs = res.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.bread {
		.is-link {
			span {
				font-weight: 700;
				text-decoration: none;
				transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
				color: #303133;
				cursor: pointer;

				&:hover {
					color: #409eff;
				}
			}
		}
	}
}

.Help {
	@extend .w100;
	@extend .h100;

	.nav {
		border-bottom: 1px dashed #eeeeee;
	}

	.list-item {
		border-bottom: 1px solid #eeeeee;

		.name {
			flex: 1;
			padding-right: 12px;
		}

		.cursor {
			font-size: 14px;
			color: #4177dd;
		}
	}

	.detail {
		.content {
			min-height: 600px;
		}
	}
}
</style>
